import { options } from './import/options';
import "./import/sliders";
import {
  // embedYoutube,
  // accordionMenu,
  setFullHeight,
  // fillInput,
  // expander,
  // scrollTo,
  // scrollHeader,
  // selector,
  // openTab,
  // showMenu,
  // setProgress,
  scrollHeader,
  // setIntersectionObserver,
  // setAspectRatioVideo,
  // Modal,
  // Timer,
  // customCursor,
} from './import/helpers';
// import { set } from 'core-js/core/dict';

class App {
  constructor() {
    const base = "/11-corpus"
    $('#svg-sprites').load(`${base}/imgs/stack/sprite.svg`);
    this.addEventListeners();
    setFullHeight();
    this.initPhrase = 'App init';

    //header-menu

    const menuTitle = document.querySelectorAll('.menu__title');

    menuTitle.forEach((el) => {
      let menuTitleArr = el.innerText.trim().split(' ');
      let html = '';
      for (let i = 0; i < menuTitleArr.length; i++) {
        html += i % 2
          ? `<span class="menu__first-word me-2" data-hover="${menuTitleArr[i]}">${menuTitleArr[i]}</span>`
          : `<span class="menu__second-word me-2" data-hover="${menuTitleArr[i]}">${menuTitleArr[i]}</span>`;
      }
      el.innerHTML = html;
    })

    // burger-menu
    let burger = document.querySelector('.hamburger');
    let pageHeader = document.querySelector(".page-header");
    burger.addEventListener('click', () => {
      pageHeader.classList.toggle("open-burger");
      burger.classList.toggle("is-active");
      if (pageHeader.classList.contains('open-burger')) {
        document.body.style.overflow = "hidden"
      } else {
        document.body.style.overflow = "unset";
      }
    }
    )

    // pour accordion
    let dropPourMenu = document.querySelectorAll('.drop-button');
    dropPourMenu.forEach((el) => {
      el.addEventListener('click', () => {
        removeIsOpenClasses();
        el.classList.add("is-open");
      })
    });
    function removeIsOpenClasses() {
      dropPourMenu.forEach(el => {
        el.classList.remove("is-open");
      });
    }

    // Plus drop-menu
    let dropAssuranceMenu = document.querySelectorAll('.plus-button');
    dropAssuranceMenu.forEach((el) => {
      el.addEventListener('click', () => {
        el.classList.toggle("is-open");
      })
    });

    //  Preloader

    var preloader = document.getElementById("page-preloader");

    if (LOADER == true) {
      document.body.onload = function () {
        setTimeout(function () {
          if (!preloader.classList.contains('done')) {
            preloader.classList.add('done')
          }
        }, 2500)
      }
      const numb = document.querySelector(".percentage");
      let counter = 0;
      setInterval(() => {
        if (counter == 100) {
          clearInterval();
        } else {
          counter += 1;
          numb.textContent = counter + "%";
        }
      }, 25);
    } else {
      preloader.classList.add('done')
    }
  }

  addEventListeners() {
    $(window).on('scroll', () => {
      scrollHeader();
    });

    $(window).on('load', () => {
      // End Loader prod
      console.log('App init\n');
    });

    $(window).on('resize', () => {
      setFullHeight();
    });

    // Elements events
    // $('.to-top, a[href^=\'#sec\']').on('click', scrollTo);
    // $('#nav-toggle').on('click', () => showMenu());
    // $('.accordion__title').on('click', accordionMenu);
  }

  init() {
    // eslint-disable-next-line
    if (DEBUG) {
      // eslint-disable-next-line
      console.log('test debug');
    }
  }
}

const APP = new App();
APP.init();
